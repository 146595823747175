import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link, useMatch } from 'react-router-dom';
import { useBrandAuth } from 'stores/auth/brand';
import { useInfluencerAuth } from 'stores/auth/influencer';
import { Dialog } from 'components/common/dialog';
import routes from 'constants/routes';
import {
  facebookIcon,
  instagramIcon,
  logoVerticalBlackText,
  tagline,
} from 'constants/images';
import styles from './Header.module.scss';

const varients = {
  MAIN: 'main',
  BRAND: 'brand',
  INF: 'influencer',
};

const INSTA_LINK = 'https://www.instagram.com/sminco_rc/';
const FB_LINK = 'https://www.facebook.com/profile.php?id=61558553753600';
function Header({ varient = varients.MAIN }) {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const isHomePage = useMatch({ path: routes.HOME, end: true });
  const isBrandHome = useMatch({ path: routes.BRAND, end: true });
  const isInfluencerHome = useMatch({ path: routes.INF, end: true });
  const isAboutPage = useMatch({ path: routes.ABOUT_US, end: true });

  const toggleMobileMenu = () => {
    setShowMobileMenu((prev) => !prev);
  };

  return (
    <header className={clsx({ [styles.header]: true })}>
      <div className={styles.topSection}>
        <div className={styles.logoSection}>
          <Link to={routes.HOME}>
            <img
              src={logoVerticalBlackText}
              alt=""
              width={246}
              className={styles.logo}
            />
          </Link>
          <span className={styles.logoSeparator} />
          <img
            src={tagline}
            alt=""
            width={352}
            height={19}
            className={styles.tagline}
          />
        </div>
        <div className={styles.actionBtnSection}>
          <div className={styles.navLinksWrapper}>
            {varient === varients.MAIN ? <MainActions /> : null}
            {varient === varients.BRAND ? <BrandActions /> : null}
            {varient === varients.INF ? <InfActions /> : null}
          </div>
          <div className={styles.socialLinks}>
            <Link to={FB_LINK} target="_blank" rel="noreferrer">
              <img src={facebookIcon} alt="" width="52" height="52" />
            </Link>
            <Link to={INSTA_LINK} target="_blank" rel="noreferrer">
              <img src={instagramIcon} alt="" width="52" height="52" />
            </Link>
          </div>
          <div className={styles.mobileNavBtn}>
            <button
              type="button"
              className={clsx(
                styles.navCheck,
                showMobileMenu && styles.navOpen
              )}
              onClick={toggleMobileMenu}
            >
              <span />
              <span />
              <span />
            </button>
          </div>
        </div>
      </div>
      <div
        className={clsx(
          styles.bottomSection,
          varient === varients.BRAND && styles.bottomSectionBrand,
          varient === varients.INF && styles.bottomSectionInfluencer
        )}
      >
        <div
          className={clsx({
            [styles.navContainer]: true,
            [styles.show]: showMobileMenu,
          })}
        >
          <div className={styles.closeBtnSpacer} />
          <nav className={styles.navLinks}>
            {!isHomePage && (
              <Link className={styles.navItems} to={routes.HOME}>
                Home
              </Link>
            )}
            {!isAboutPage && (
              <Link className={styles.navItems} to={routes.ABOUT_US}>
                About
              </Link>
            )}
            <Link className={styles.navItems} to={routes.CASE_STUDIES}>
              Case Studies
            </Link>
            {!isBrandHome && (
              <Link className={styles.navItems} to={routes.BRAND}>
                Are you a Brand?
              </Link>
            )}
            {!isInfluencerHome && (
              <Link className={styles.navItems} to={routes.INF}>
                Are you an influencer?
              </Link>
            )}
            <div className={styles.actionBtnSectionMobile}>
              {varient === varients.MAIN ? <MainActions /> : null}
              {varient === varients.BRAND ? <BrandActions /> : null}
              {varient === varients.INF ? <InfActions /> : null}
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}

const MainActions = () => (
  <div className={styles.navLinks}>
    <Link
      className={clsx(styles.navItems, styles.signInBtn)}
      to={routes.INF_LOGIN}
    >
      Sign In
    </Link>
    <Link
      className={clsx(styles.navItems, styles.signupBtn)}
      to={routes.INF_SIGNUP}
    >
      Sign Up
    </Link>
    {/* <Dialog
      size="lg"
      isTransparentPanel
      render={({ open }) => (
        <button
          type="button"
          onClick={open}
          className={clsx(styles.navItems, styles.signInBtn)}
        >
          Sign In
        </button>
      )}
    >
      <div className={styles.signInDialogPanel}>
        <Link to={routes.BRAND_LOGIN}>Sign In as Brand</Link>
        <Link to={routes.INF_LOGIN}>Sign In as Influencer</Link>
      </div>
    </Dialog>
    <Dialog
      size="lg"
      isTransparentPanel
      render={({ open }) => (
        <button
          type="button"
          onClick={open}
          className={clsx(styles.navItems, styles.signupBtn)}
        >
          Sign Up
        </button>
      )}
    >
      <div className={styles.signInDialogPanel}>
        <Link to={routes.BRAND_SIGNUP}>Signup as Brand</Link>
        <Link to={routes.INF_SIGNUP}>Signup as Influencer</Link>
      </div>
    </Dialog> */}
  </div>
);

const BrandActions = () => {
  const token = useBrandAuth((state) => state.token);
  return (
    <div className={styles.navLinks}>
      <Link
        className={clsx(
          styles.navItems,
          styles.brandSignUpBtn,
          styles.signupBtn
        )}
        to={routes.CONTACT_US}
        state={{ type: 'brand' }}
      >
        Connect With Us
      </Link>
      {/* {token ? (
        <Link
          className={clsx(
            styles.navItems,
            styles.brandSignUpBtn,
            styles.signupBtn
          )}
          to={routes.BRAND_DASHBOARD}
        >
          Go to Dashboard
        </Link>
      ) : (
        <>
          <Link
            className={clsx(styles.navItems, styles.signInBtnInfluencer)}
            to={routes.BRAND_LOGIN}
          >
            Sign In
          </Link>
          <Link
            className={clsx(
              styles.navItems,
              styles.brandSignUpBtn,
              styles.signupBtn
            )}
            to={routes.BRAND_SIGNUP}
          >
            Sign Up
          </Link>
        </>
      )} */}
    </div>
  );
};

const InfActions = () => {
  const token = useInfluencerAuth((state) => state.token);
  return (
    <div className={styles.navLinks}>
      {token ? (
        <Link
          className={clsx(
            styles.navItems,
            styles.influencerSignUpBtn,
            styles.signupBtn
          )}
          to={routes.INF_SIGNUP}
        >
          Go to Dashboard
        </Link>
      ) : (
        <>
          <Link
            className={clsx(styles.navItems, styles.signInBtnInfluencer)}
            to={routes.INF_LOGIN}
          >
            Sign In
          </Link>
          <Link
            className={clsx(
              styles.navItems,
              styles.influencerSignUpBtn,
              styles.signupBtn
            )}
            to={routes.INF_SIGNUP}
          >
            Sign Up
          </Link>
        </>
      )}
    </div>
  );
};

const varientValues = Object.values(varients);

Header.propTypes = {
  varient: PropTypes.oneOf(varientValues),
};

export default Header;
